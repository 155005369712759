<template>
  <v-card class="d-flex align-center font-weight-bold justify-center pa-4 fill-width fill-height orange lighten-4" light outlined @click.prevent="loadingStep = 0, dialogConfirmExport = true">
    <v-icon small class="mr-1">
      mdi-file-export
    </v-icon>
    Export
    <v-dialog
      v-model="dialogConfirmExport"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title class="justify-center">
          Export Data
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4" style="max-height: 340px;">
          <div v-if="loadingStep === 0">
            <v-card class="mt-4 mb-4 pa-4 d-flex fill-width rounded-lg font-weight-bold justify-center c-pointer text-center" outlined @click.prevent="ProcessExport()">
              Export Data sesuai Filter Tabel Saat Ini
            </v-card>
            <v-card class="mb-4 pa-4 d-flex fill-width rounded-lg font-weight-bold justify-center c-pointer" outlined @click.prevent="ProcessExport(true)">
              Export Semua Data
            </v-card>
          </div>
          <div v-if="loadingStep === 2 || loadingStep === 3" class="d-flex flex-column align-center justify-center fill-width py-8">
            <v-progress-circular
              :size="40"
              :width="3"
              color="primary"
              indeterminate
            />
            <div class="pt-1">
              {{ loadingStep === 2 ? 'Request data' : 'Preparing data' }} ...
            </div>
          </div>
          <div v-else-if="loadingStep === 4">
            <xlsx-workbook>
              <xlsx-sheet
                :sheet-name="sheet.name"
                :collection="sheet.data"
              />
              <xlsx-download :filename="'kbrikl-data-' + (new Date()).toISOString() + '.xlsx'" :download="DownloadData">
                <v-btn depressed block color="primary" class="white--text text-capitalize rounded-pill" @click.prevent="DownloadData">
                  Download Data
                </v-btn>
              </xlsx-download>
            </xlsx-workbook>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center">
          <v-btn
            color="grey darken-1"
            outlined
            depressed
            small
            class="rounded-pill text-capitalize px-8"
            @click="dialogConfirmExport = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'
export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    rangeDates: {
      type: String,
      default: ''
    },
    coordinator: {
      type: Number,
      default: 0
    },
    status: {
      type: Number,
      default: -1
    },
    sortBy: {
      type: String,
      default: ''
    },
    sort: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialogConfirmExport: false,
    loadingStep: 0,
    sheet: {
      name: '',
      data: []
    }
  }),
  methods: {
    DownloadData () {
      this.dialogConfirmExport = false
    },
    async ProcessExport (allData) {
      this.loadingStep = 0
      this.sheet.name = 'KBRI DATA'
      this.sheet.data = []

      const query = allData ? '?page=0&limit=10000000&sortby=created&sort=DESC' : ('?page=0&limit=10000000&sortby=' + this.sortBy + '&sort=' + this.sort + '&q=' + (this.search || '') + (parseInt(this.status) > -1 ? '&status=' + this.status : '') + ('&coordinator=' + parseInt(this.coordinator) || 0) + this.rangeDates)

      this.loadingStep = 1
      this.$store.dispatch('customer/CUSTOMER_SEARCH', query)
        .then((res) => {
          if (res.status) {
            try {
              const data = res.data.data.data || []
              if (data.length) {
                this.ParsingData(data)
              } else {
                this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada data!' })
                this.loadingStep = 0
              }
            } catch {
              this.$store.dispatch('TOAST', { show: true, message: 'Gagal export data!' })
              this.loadingStep = 0
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            this.loadingStep = 0
          }
        })
    },
    ParsingData (data) {
      this.loadingStep = 2
      for (let a = 0; a < data.length; a++) {
        const obj = Object.assign({}, data[a])
        const coordinator = {}
        if (obj.coordinator_name) {
          const coorSplit = obj.coordinator_name.split(',')
          coordinator.name = coorSplit[0] || ''
          coordinator.phone = coorSplit[1] || ''
          coordinator.email = coorSplit[2] || ''
        }
        obj.estimated_stay_year = 0
        obj.estimated_stay_month = 0
        if (parseInt(obj.estimated_stay)) {
          obj.estimated_stay_year = Math.floor((parseInt(obj.estimated_stay) || 0) / 365) || 0
          obj.estimated_stay_year = obj.estimated_stay_year < 0 ? 0 : obj.estimated_stay_year
          const restOfMonthDecimal = ((parseInt(obj.estimated_stay) || 0) / 365) - obj.estimated_stay_year
          obj.estimated_stay_month = Math.ceil(restOfMonthDecimal * 12) || 0
          obj.estimated_stay_month = obj.estimated_stay_month < 0 ? 0 : obj.estimated_stay_month
        }
        const o = {
          No: (a + 1),
          'Nama (*)': obj.name || '',
          'Tempat Lahir': obj.birthday_place || '',
          'Tanggal Lahir (dd/mm/yyyy)': this.$ConvertExcelDate(obj.birthday_date) || '',
          'Jenis Kelamin': obj.sex || '',
          NIK: obj.nik || '',
          'Nomor Paspor (*)': obj.passport || '',
          'Tanggal Habis Berlaku Paspor (dd/mm/yyyy)': this.$ConvertExcelDate(obj.passport_expire) || '',
          'Nomor KTKLN': obj.stay_ktkln || '',
          'Waktu Kedatangan (dd/mm/yyyy)': this.$ConvertExcelDate(obj.ln_arrival) || '',
          'Alamat di Luar Negeri (*)': obj.stay_address || '',
          Negara: obj.ln_country || '',
          'Negara ID': obj.ln_country_id || '',
          Provinsi: obj.ln_province || '',
          Kota: obj.ln_city || '',
          'Kode Pos': obj.ln_postal_code || '',
          'Kode Area Nomor Telepon': obj.phone_code || '',
          'Nomor Telepon (*)': obj.phone || '',
          Email: obj.email || '',
          'Nomor Visa / Izin Tinggal': obj.visa || '',
          'Masa Berlaku Visa / Izin Tinggal Awal (dd/mm/yyyy)': this.$ConvertExcelDate(obj.visa_validity_from) || '',
          'Masa Berlaku Visa / Izin Tinggal Akhir (dd/mm/yyyy)': this.$ConvertExcelDate(obj.visa_validity_to) || '',
          'Perkiraan Lama Menetap Tahun': obj.estimated_stay_year || '',
          'Perkiraan Lama Menetap Bulan': obj.estimated_stay_month || '',
          'Tujuan Menetap (*)': obj.stay_reason || 'Bekerja',
          'Bidang Kerja': obj.stay_reason === 'Bekerja' ? (obj.stay_data1 || '') : '',
          Pekerjaan: obj.stay_reason === 'Bekerja' ? (obj.stay_data2 || '') : '',
          'Nama Perusahaan / Pengguna Jasa': obj.stay_reason === 'Bekerja' ? (obj.stay_name || '') : '',
          'Alamat Pekerjaan': obj.stay_reason === 'Bekerja' ? (obj.stay_address || '') : '',
          PPTKIS: obj.stay_reason === 'Bekerja' ? (obj.stay_pptkis || '') : '',
          'Agen Penyalur di Luar Negeri': obj.stay_reason === 'Bekerja' ? (obj.stay_agent || '') : '',
          Sekolah: obj.stay_reason === 'Belajar' ? (obj.stay_name || '') : '',
          Jenjang: obj.stay_reason === 'Belajar' ? (obj.stay_data1 || '') : '',
          'Program / Bidang Studi': obj.stay_reason === 'Belajar' ? (obj.stay_data2 || '') : '',
          'Lama Pendidikan': obj.stay_education_length || '',
          Keterangan: obj.stay_notes || '',
          'Kontak Darurat di Luar Negeri - Nama': obj.emergency_ln || '',
          'Kontak Darurat di Luar Negeri - Hubungan': obj.emergency_relation_ln || '',
          'Kontak Darurat di Luar Negeri - Email': obj.emergency_email_ln || '',
          'Kontak Darurat di Luar Negeri - Kode Area Nomor Telepon': obj.emergency_phone_code_ln || '',
          'Kontak Darurat di Luar Negeri - Nomor Telepon': obj.emergency_phone_ln || '',
          'Kontak Darurat di Indonesia - Nama': obj.emergency || '',
          'Kontak Darurat di Indonesia - Hubungan': obj.emergency_relation || '',
          'Kontak Darurat di Indonesia - Email': obj.emergency_email || '',
          'Kontak Darurat di Indonesia - Kode Area Nomor Telepon': obj.emergency_phone_code || '',
          'Kontak Darurat di Indonesia - Nomor Telepon': obj.emergency_phone || '',
          Dokumen: obj.documents || '',
          'Koordinator - Nama': coordinator.name || '',
          'Koordinator - Email': coordinator.email || '',
          'Koordinator - Telepon': coordinator.phone || '',
          'Status Saat Ini': parseInt(obj.status) === 2 ? 'Berada di Indonesia' : parseInt(obj.status) === 1 ? 'Sedang Berada di Luar Negeri' : '-',
          Status: obj.status || 0
        }
        this.sheet.data.push(o)
      }
      this.loadingStep = 3
      setTimeout(() => {
        this.loadingStep = 4
      }, 1000)
    },
    parsed (d) {
      if (d.SheetNames.length) {
        this.sheets = d.Sheets || {}
        this.sheetSelected = null
        this.dialogConfirmUpload = true
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada sheet/halaman pada file!' })
      }
    }
  }
}
</script>
